
var prevScrollpos = window.pageYOffset;
window.onscroll = function() {
  var currentScrollPos = window.pageYOffset;
  if (prevScrollpos < currentScrollPos && currentScrollPos > 2) {
    document.querySelector("header").classList.add("hide");
  } else if (prevScrollpos > currentScrollPos && currentScrollPos > 2) {
    document.querySelector("header").classList.add("small");
    document.querySelector("header").classList.remove("hide");
  }
  else {
    document.querySelector("header").classList.remove("hide");
    document.querySelector("header").classList.remove("small");
  }
  prevScrollpos = currentScrollPos;
} 

/*Dropzone.autoDiscover = false;
  && currentScrollPos > 168
    && currentScrollPos > 168
$(document).ready(function() {
    
    new WOW().init();
    $('.date-field').datepicker({changeYear: true });
    $('.project-date-field').datepicker({changeYear: true, yearRange: "-0:+2"});
    $.datepicker.regional['pl'] = {
        closeText: "Zamknij",
        prevText: "&#x3C;Poprzedni",
        nextText: "Następny&#x3E;",
        currentText: "Dziś",
        monthNames: [ "Styczeń", "Luty", "Marzec", "Kwiecień", "Maj", "Czerwiec",
        "Lipiec", "Sierpień", "Wrzesień", "Październik", "Listopad", "Grudzień" ],
        monthNamesShort: [ "Sty", "Lu", "Mar", "Kw", "Maj", "Cze",
        "Lip", "Sie", "Wrz", "Pa", "Lis", "Gru" ],
        dayNames: [ "Niedziela", "Poniedziałek", "Wtorek", "Środa", "Czwartek", "Piątek", "Sobota" ],
        dayNamesShort: [ "Nie", "Pn", "Wt", "Śr", "Czw", "Pt", "So" ],
        dayNamesMin: [ "N", "Pn", "Wt", "Śr", "Cz", "Pt", "So" ],
        weekHeader: "Tydz",
        dateFormat: "dd.mm.yy",
        firstDay: 1,
        isRTL: false,
        showMonthAfterYear: false,
        yearSuffix: "" 
    };
    
    $.datepicker.setDefaults(
        $.extend(
          {'dateFormat':'dd.mm.yy', 'yearRange': "-130:+0"},
          $.datepicker.regional['pl']
        )
      );
    $(document).on("click", function (e) {
        $("#burger-container").removeClass("open");
        $('.menu-container').removeClass("open");
        $('.menu-container li.has-submenu').removeClass("open");
    });
    $(document).on('click', 'a[href^="#"]', function (event) {
        event.preventDefault();  
        if($(this).attr('href').length > 1){
            $('html, body').animate({
                scrollTop: $($.attr(this, 'href')).offset().top
            }, 500);
        }
        
    });   
    $('.site-navigation').click(function(e){
        e.stopPropagation();
    });
    $('.uploaded-files .file i').click(function(e){
        $(this).parent().remove();
    });
    $('.menu-container>ul>li.has-submenu>span').click(function(e){
        e.preventDefault();
        e.stopPropagation();
        $('.menu-container li.has-submenu').removeClass("open");
        $(this).parent().toggleClass('open');
    });
    $('.menu-container').click(function(e){
        e.stopPropagation();
    });
    $('.banner-scroll').click(function(e){
        e.preventDefault();
        var target = $(e.target).closest('section').nextAll('section:first');
        if (!target.length) return;
        $('html,body').animate({scrollTop: $(target).offset().top-80},'slow');
      });
    $(".filters").niceScroll({
        cursorwidth: "18px",
        autohidemode: false,
        horizrailenabled:false
    });
});


*/

// Can also be used with $(document).ready()




$(window).load(function () {
  $('.flexsliderProduct').flexslider({
    animation: "fade",
    controlNav: "thumbnails"
  });
});


$(window).load(function () {
  $('.flexslider').each(function () {
    $(this).flexslider({
      animation: "fade",
      controlsContainer: $(this).find(".custom-controls-container")
      //   customDirectionNav: $(".custom-navigation a")
    });
  })
});



const menu = document.querySelector('.hamburger');
const header = document.querySelector('.main-header');

menu.addEventListener('click', () => {
  menu.classList.toggle('hamburger--active');
  header.classList.toggle('not-hide');
});


const showText = document.querySelector(".product__desc--btn");
const textAll = document.querySelector(".product__desc");
if (textAll) {


  showText.addEventListener("click", () => {
    textAll.classList.toggle("showAll");
  });
}
const menuMobile = document.querySelector(".collapse1");

menu.addEventListener("click", () => {
  menuMobile.classList.toggle("active-menu");
});


function readColor(d) {
 // console.log(d.getAttribute("data-colorId"));
  const imgPoduszki = document.querySelectorAll('[data-color=' + d.getAttribute("data-colorId") + ']');

  const imgActive = document.querySelectorAll('.flexsliderProduct ul li .active');
  const slideColor = document.querySelectorAll('.flexsliderProduct ul .slideColor');
  const slideThumbs = document.querySelectorAll('.flex-control-thumbs li img');

  console.log(slideThumbs);

  for (const imgA of imgActive) {
    imgA.classList.remove('active');
  }

  for (const imgPoduszka of imgPoduszki) {
    imgPoduszka.classList.add('active');
  }
 
  slideThumbs[0].src = imgPoduszki[0].src;
  slideThumbs[1].src = imgPoduszki[1].src;
  slideThumbs[2].src = imgPoduszki[2].src;
  slideThumbs[3].src = imgPoduszki[3].src;
}


document.querySelector("#wynik").addEventListener("change", ()=>{
let iloscProduktow = Number(document.querySelector("#wynik").value);
let cena =iloscProduktow * 470;
document.querySelector("#cena").innerHTML = cena;
suma();
});
document.querySelector("#wynik2").addEventListener("change", ()=>{
  let iloscProduktow = Number(document.querySelector("#wynik2").value);
  let cena =iloscProduktow * 490;
  document.querySelector("#cena2").innerHTML = cena;
  suma();
  });

  document.querySelector("#wynik3").addEventListener("change", ()=>{
    let iloscProduktow = Number(document.querySelector("#wynik3").value);
    let cena =iloscProduktow * 590;
    document.querySelector("#cena3").innerHTML = cena;
    suma();
    });
    
function suma(){

  let cena1 = Number(document.querySelector("#cena").innerHTML);
  let cena2 = Number(document.querySelector("#cena2").innerHTML);
  let cena3 = Number(document.querySelector("#cena3").innerHTML);

  document.querySelector("#suma").innerHTML = cena1 + cena2 + cena3;
}



function plusKoszyk()
{
let iloscProduktow = Number(document.querySelector("#wynik").value);
let cena = Number(document.querySelector("#cena").innerHTML);
iloscProduktow = iloscProduktow + 1;
cena = cena + 470;
document.querySelector("#wynik").value = iloscProduktow;
document.querySelector("#cena").innerHTML = cena;
suma();
}
function minusKoszyk()
{
  let iloscProduktow = Number(document.querySelector("#wynik").value);
  let cena = Number(document.querySelector("#cena").innerHTML);
 
  iloscProduktow = iloscProduktow - 1;
  cena = cena - 470;
  document.querySelector("#wynik").value = iloscProduktow;
  document.querySelector("#cena").innerHTML = cena;
  if(iloscProduktow == 0)
  {
    document.querySelector("#product1").classList.add("noactive");
  }
  suma();
}
function usunKoszyk(){
  document.querySelector("#product1").classList.add("noactive");
  suma();
}
function plusKoszyk2()
{
let iloscProduktow = Number(document.querySelector("#wynik2").value );
let cena = Number(document.querySelector("#cena2").innerHTML);
iloscProduktow = iloscProduktow + 1;
cena = cena + 490;
document.querySelector("#wynik2").value  = iloscProduktow;
document.querySelector("#cena2").innerHTML = cena;
suma();
}
function minusKoszyk2()
{
  let iloscProduktow = Number(document.querySelector("#wynik2").value );
  let cena = Number(document.querySelector("#cena2").innerHTML);
 
  iloscProduktow = iloscProduktow - 1;
  cena = cena - 590;
  document.querySelector("#wynik2").value  = iloscProduktow;
  document.querySelector("#cena2").innerHTML = cena;
  if(iloscProduktow == 0)
  {
    document.querySelector("#product2").classList.add("noactive");
  }
  suma();
}
function usunKoszyk2(){
  document.querySelector("#product2").classList.add("noactive");
  suma();
}

function plusKoszyk3()
{
let iloscProduktow = Number(document.querySelector("#wynik3").value );
let cena = Number(document.querySelector("#cena3").innerHTML);
iloscProduktow = iloscProduktow + 1;
cena = cena + 470;
document.querySelector("#wynik3").value  = iloscProduktow;
document.querySelector("#cena3").innerHTML = cena;
suma();
}
function minusKoszyk3()
{
  let iloscProduktow = Number(document.querySelector("#wynik3").value );
  let cena = Number(document.querySelector("#cena3").innerHTML);

  iloscProduktow = iloscProduktow - 1;
  cena = cena - 470;
  document.querySelector("#wynik3").value  = iloscProduktow;
  document.querySelector("#cena3").innerHTML = cena;
  if(iloscProduktow == 0)
  {
    document.querySelector("#product3").classList.add("noactive");
  }
  suma();
}
function usunKoszyk3(){
  document.querySelector("#product3").classList.add("noactive");
  suma();
}


